define("discourse/plugins/discourse-follow/discourse/routes/follow-index", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FollowIndexRoute extends _discourse.default {
    static #_ = dt7948.g(this.prototype, "router", [_service.inject]);
    #router = (dt7948.i(this, "router"), void 0);
    beforeModel() {
      const model = this.modelFor("user");
      const canSeeFollowers = model.can_see_followers;
      const canSeeFollowing = model.can_see_following;
      if (this.currentUser?.id === model.id) {
        this.router.replaceWith("feed");
      } else if (canSeeFollowing) {
        this.router.replaceWith("following");
      } else if (canSeeFollowers) {
        this.router.replaceWith("followers");
      } else {
        this.router.replaceWith("user");
      }
    }
  }
  _exports.default = FollowIndexRoute;
});